* {
  box-sizing: border-box; }

body,
html {
  height: 100%;
  min-height: 100%;
  font-size: 62.5%; }

.input-error {
  display: none;
  color: red;
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
  text-align: left;
  width: 200px; }

body {
  font-family: "Gotham";
  margin: 0;
  background-image: url("../assets/imgs/background.jpg");
  background-size: cover;
  color: #fff; }

input {
  border-radius: 0; }

.error-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }

.welcome__title {
  font-family: "Gotham";
  font-weight: 700;
  font-size: 70px;
  margin: 0 0 10px;
  padding-top: 50px;
  line-height: 57px; }

.welcome__message {
  font-family: "Gotham", Helvetica, Arial;
  font-size: 22px;
  margin: 0; }

.welcome__about-yourself {
  padding: 20px; }

.content {
  text-align: center;
  height: 100%;
  overflow: hidden; }

#checkIn {
  height: 100%; }

.check-in-content {
  background: #fff; }

.check-in-content__top {
  background-image: url("../assets/imgs/background.jpg");
  background-size: cover; }

.check-in-content__form {
  padding-top: 3rem;
  background: #fff; }

.check-in-form {
  padding: 3rem;
  color: black;
  width: 100%; }

.header {
  background-image: url("../assets/imgs/background.jpg");
  background-size: cover;
  color: white;
  padding: 1rem;
  text-align: left;
  position: fixed;
  width: 100%;
  top: 0; }

.header__link {
  color: white;
  text-decoration: none; }

.home-page__buttons-wrapper-container {
  list-style: none;
  margin: 2rem 0 0;
  padding: 0;
  text-align: center; }

.home-page__buttons-wrapper:first-child {
  margin-bottom: 2rem; }

.button {
  text-decoration: none;
  display: inline-block;
  padding: 2.5rem;
  text-align: center;
  background: #ce3a8b;
  color: white;
  font-size: 3rem;
  border-radius: 2px;
  box-shadow: 0px 0px 50px -25px #000;
  font-family: "Gotham", arial;
  width: 23vw; }

.visitor-text-line {
  display: block;
  margin-bottom: 3rem;
  position: relative;
  font-size: 3rem; }

.visitor-text-line > div {
  display: inline-block; }

.visitor-custom-select > div {
  width: 273px; }

.visitor-input {
  border: none;
  border-bottom: 1px dotted;
  outline: none;
  color: #ce3a8b;
  font-size: 16px; }

input.visitor-input {
  width: 100px;
  box-sizing: content-box;
  text-align: center;
  padding: 0 20px; }

.visitor-select {
  border-radius: 0;
  padding: 10px 25px 10px 5px;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.visitor-select-wrapper > div {
  position: relative; }

.visitor-select-wrapper > div:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #ce3a8b; }

.visitor-submit-button-container {
  text-align: center; }

.visitor-submit-button {
  border: none;
  margin: 20px auto 0; }

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.visitor-newsletter-text-wrapper {
  float: left;
  margin: 13px 10px 0 0; }

.iframe {
  border: 0;
  padding-top: 37px; }

.start-again-link {
  color: #2e2b28;
  text-decoration: none;
  display: inline-block;
  padding: 3rem;
  font-size: 2rem; }

.hidden {
  display: none !important;
  height: 0 !important;
  overflow: hidden; }

.our-work-iframe {
  width: 100%;
  border: none;
  height: 65vh; }

.thanks-page, .welcome-page {
  height: 100%; }

.thanks-page__element--top {
  height: 35%; }

.thanks-page__element--bottom {
  height: 65%;
  overflow-y: scroll;
  background: #333;
  position: relative; }

.welcome-page__element {
  height: 100%;
  min-height: 100vh;
  padding: 25vh 0; }

.welcome-page__name {
  font-size: 4rem;
  margin: 0 0 4rem 0; }

.iframe-container {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll; }

.our-work-iframe__title {
  width: 100%;
  padding: 10px;
  background: #333;
  text-transform: uppercase;
  font-family: "Gotham", Helvetica, Arial; }

.start-again-link {
  color: #ce3a8b; }

.visitor-drink-select-wrapper {
  text-align: left; }

.visitor-drink-select-wrapper-content {
  display: inline-block; }

.visitor-text-main-line ul,
label.error {
  list-style: none;
  font-size: 7px;
  margin: 0;
  padding: 0;
  color: red; }

.visitor-select.selectized {
  display: block !important;
  height: 0;
  padding: 0; }

.selectize-control.single .selectize-input:after {
  border-color: #ce3a8b transparent transparent transparent !important; }

.selectize-input > input {
  min-width: 100px; }

.selectize-input.items input[type="text"] {
  position: relative; }

label.error {
  position: absolute;
  top: -15px;
  right: 0; }

.visitor-custom-select label.error {
  right: auto;
  transform: translateX(-50%);
  left: 50%;
  top: -5px; }

.visitor-custom-select > div {
  position: relative; }

.input-text-holder {
  display: inline-block;
  position: fixed;
  top: -100px;
  visibility: hidden; }

.toast-style {
  font-size: 1.6rem;
  padding: 1.2rem; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-MediumItalic.woff2") format("woff2"), url("fonts/Gotham-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-LightItalic.woff2") format("woff2"), url("fonts/Gotham-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-Black.woff2") format("woff2"), url("fonts/Gotham-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-BlackItalic.woff2") format("woff2"), url("fonts/Gotham-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: "Gotham Book";
  src: url("fonts/Gotham-Book.woff2") format("woff2"), url("fonts/Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-BoldItalic.woff2") format("woff2"), url("fonts/Gotham-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-Light.woff2") format("woff2"), url("fonts/Gotham-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-Medium.woff2") format("woff2"), url("fonts/Gotham-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Gotham Book";
  src: url("fonts/Gotham-BookItalic.woff2") format("woff2"), url("fonts/Gotham-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }
